import axios from "axios";
import { MetaData } from "@patron/data-access-server";

type ExtraData = MetaData & { version: string };
export interface UploadFileProps {
  formData: FormData;
  files: FileList;
  endPointUrl: string;
  parent?: any;
  folder?: string;
  extraData?: ExtraData;
}

export const uploadFile = async ({
  formData,
  files,
  endPointUrl,
  parent,
  folder,
  extraData,
}: UploadFileProps) => {
  for (let index = 0; index < files.length; index++) {
    const element = files[index];
    formData.append("files", element);
  }

  if (extraData) {
    formData.append("extraData", JSON.stringify(extraData));
  }
  const repsonse = await axios
    .put(endPointUrl, formData, {
      params: {
        parent: parent,
        folder: folder,
      },
      withCredentials: true,
    })
    .catch((error) => {
      console.error(error);
    });
  //@ts-ignore
  return repsonse.data;
};

export const uploadSupportFile = async ({
  formData,
  files,
  endPointUrl,
  parent,
  folder,
  extraData,
}: UploadFileProps) => {
  for (let index = 0; index < files.length; index++) {
    const element = files[index];
    formData.append("files", element);
  }
  if (extraData) {
    formData.append("extraData", JSON.stringify(extraData));
  }

  const repsonse = await axios
    .put(endPointUrl, formData, {
      params: {
        parent: parent,
        folder: folder,
      },
      withCredentials: true,
    })
    .catch((error) => {
      console.error(error);
    });
  //@ts-ignore
  return repsonse.data;
};
